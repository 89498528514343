import { dia, ui, shapes, elementTools, util, V, g } from '@clientio/rappid';
import { JointBoardTypes, JointItemTypes } from './JointTypes.js';
import JointSettings from '../JointSettings.js';
import JointUtils from '../utils/JointUtils.js';

const JointCustomRectangle = shapes.standard.Rectangle.define(
	'operations.CustomRectangle',
	{
		attrs: {
			body: {
				cursor: 'default',
				fill: 'white',
				stroke: 'white',
				strokeWidth: 1
			},
			label: {
				cursor: 'default',
				fontWeight: 'bold',
				fill: 'black'
			},
			secondLabel: {
				cursor: 'default',
				fontWeight: 'bold',
				textAnchor: 'middle',
				refX: '50%',
				refY: '50%'
			}
		}
	},
	{},
	/* Funciones de ejemplo https://resources.jointjs.com/tutorial/custom-elements */
	{
		createNew: function (operationsModel) {
			let rectangle = new JointCustomRectangle();

			// MARKUP

			rectangle.markup = [
				{
					tagName: 'rect',
					selector: 'body'
				},
				{
					tagName: 'text',
					selector: 'label'
				},
				{
					tagName: 'text',
					selector: 'secondLabel'
				}
			];

			switch (operationsModel.itemType) {
				case JointItemTypes.Vessel: {
					rectangle.markup.push({
						tagName: 'image',
						selector: 'berthingImage'
					});
					rectangle.markup.push({
						tagName: 'image',
						selector: 'info'
					});
					break;
				}
				case JointItemTypes.BerthBlock: {
					rectangle.markup.push({
						tagName: 'rect',
						selector: 'blockImage'
					});
					break;
				}
				case JointItemTypes.Booking: {
					rectangle.markup.splice(1, 0, {
						tagName: 'rect',
						selector: 'blockImage'
					});
					break;
				}
				case JointItemTypes.ResourceBlock: {
					rectangle.markup.push({
						tagName: 'rect',
						selector: 'blockImage'
					});
					break;
				}
				case JointItemTypes.ResourceAllocation: {
					rectangle.markup.push({
						tagName: 'rect',
						selector: 'leftSlack'
					});
					rectangle.markup.push({
						tagName: 'rect',
						selector: 'rightSlack'
					});
					break;
				}
			}

			// ATTRIBUTES

			switch (operationsModel.itemType) {
				case JointItemTypes.Debug: {
					rectangle.attr('body/stroke', 'red');
					rectangle.attr('body/fill', 'pink');
					break;
				}
				case JointItemTypes.DayOfWeek: {
					rectangle.attr('label/text-anchor', 'start');
					rectangle.attr('label/font-size', '16');
					rectangle.attr('label/ref-y', '-10');
					rectangle.attr('label/ref-x', '-40');
					break;
				}
				case JointItemTypes.Month: {
					rectangle.attr('body/fill', 'transparent');
					rectangle.attr('body/stroke', 'transparent');
					rectangle.attr('label/font-weight', 'regular');
					rectangle.attr('label/text-anchor', 'start');
					rectangle.attr('label/font-size', '15');
					rectangle.attr('label/ref-y', '-10');
					rectangle.attr('label/ref-x', '-40');
					break;
				}
				case JointItemTypes.HourOfDay: {
					rectangle.attr('label/font-weight', 'regular');
					rectangle.attr('label/font-size', '12');
					break;
				}
				case JointItemTypes.DayForecast: {
					rectangle.attr('body/fill', 'transparent');
					rectangle.attr('body/stroke', 'transparent');
					rectangle.attr('label/font-weight', 'regular');
					rectangle.attr('label/font-size', '11');
					rectangle.attr('label/text-anchor', 'start');
					rectangle.attr('label/ref-y', '-10');
					rectangle.attr('label/ref-x', '-40');
					break;
				}
				case JointItemTypes.Vessel: {
					rectangle.attr('body/fill', operationsModel.stop.statusColor);
					rectangle.attr('body/stroke', operationsModel.stop.statusOutlineColor);
					rectangle.attr('secondLabel/text', JointUtils.getSecondLabelFromStop(operationsModel.stop));
					rectangle.attr('label/fill', 'white');
					rectangle.attr('secondLabel/fill', 'white');
					this.setRoundedBorders(rectangle, JointSettings.shapes.berthplanner.rounded);

					if (operationsModel.dragabble) {
						rectangle.attr('body/cursor', 'move');
						rectangle.attr('label/cursor', 'move');
						rectangle.attr('secondLabel/cursor', 'move');
						rectangle.attr('berthingImage/cursor', 'move');
					} else {
						rectangle.attr('body/cursor', 'pointer');
						rectangle.attr('label/cursor', 'pointer');
						rectangle.attr('secondLabel/cursor', 'pointer');
						rectangle.attr('berthingImage/cursor', 'pointer');
					}

					//this.setInfoImage();
					//this.calculateBerthingDirection();

					break;
				}
				case JointItemTypes.BerthBlock: {
					rectangle.attr('body/fill', JointSettings.colors.berthplanner.block);
					rectangle.attr('body/stroke', JointSettings.colors.berthplanner.block_border);
					rectangle.attr('blockImage/fill', 'url(#backgroundBlackPattern)');

					if (operationsModel.dragabble) {
						rectangle.attr('body/cursor', 'move');
						rectangle.attr('label/cursor', 'move');
						rectangle.attr('blockImage/cursor', 'move');
					} else {
						rectangle.attr('body/cursor', 'default');
						rectangle.attr('label/cursor', 'default');
						rectangle.attr('blockImage/cursor', 'default');
					}

					break;
				}
				case JointItemTypes.Booking: {
					rectangle.attr('body/fill', JointSettings.colors.berthplanner.booking);
					rectangle.attr('body/stroke', JointSettings.colors.berthplanner.booking_border);
					rectangle.attr('blockImage/fill', 'url(#backgroundYellowPattern)');

					if (operationsModel.dragabble) {
						rectangle.attr('body/cursor', 'move');
						rectangle.attr('label/cursor', 'move');
						rectangle.attr('blockImage/cursor', 'move');
					} else {
						rectangle.attr('body/cursor', 'default');
						rectangle.attr('label/cursor', 'default');
						rectangle.attr('blockImage/cursor', 'default');
					}

					break;
				}
				case JointItemTypes.ResourceHour: {
					rectangle.attr('label/text-anchor', 'middle');
					break;
				}
				case JointItemTypes.ResourceName: {
					rectangle.attr('body/stroke', '#d5d9dc');
					rectangle.attr('label/text-anchor', 'start');
					rectangle.attr('label/ref-x', '-80');
					//this.rectangle.attr('label/ref-y', '-5');
					break;
				}
				case JointItemTypes.ResourceCode: {
					rectangle.attr('body/fill', 'transparent');
					rectangle.attr('body/stroke', 'transparent');
					rectangle.attr('label/font-weight', 'regular');
					rectangle.attr('label/text-anchor', 'start');
					rectangle.attr('label/ref-x', '-120');
					rectangle.attr('label/ref-y', '-5');
					break;
				}
				case JointItemTypes.ResourceAllocation: {
					rectangle.attr('body/fill', operationsModel.resourceallocation.statuscolor);
					rectangle.attr('body/stroke', operationsModel.resourceallocation.statusoutlinecolor);
					rectangle.attr('label/text', operationsModel.label);
					rectangle.attr('label/font-size', '12');
					this.setRoundedBorders(rectangle, JointSettings.shapes.berthplanner.rounded);

					if (!operationsModel.dragabble) {
						rectangle.attr('body/cursor', 'default');
						rectangle.attr('label/cursor', 'default');
					}

					//this.setSlackImages();

					break;
				}
				case JointItemTypes.ResourceActualAllocation: {
					rectangle.attr('body/fill', operationsModel.resourceallocation.statuscolor);
					rectangle.attr('body/stroke', operationsModel.resourceallocation.statusoutlinecolor);
					rectangle.attr('body/opacity', '0.50');
					rectangle.attr('label/text', operationsModel.label);
					rectangle.attr('label/font-size', '12');
					rectangle.attr('label/opacity', '0.30');
					this.setRoundedBorders(rectangle, JointSettings.shapes.berthplanner.rounded);

					if (!operationsModel.dragabble) {
						rectangle.attr('body/cursor', 'default');
						rectangle.attr('label/cursor', 'default');
						rectangle.attr('blockImage/cursor', 'default');
					}

					break;
				}
				case JointItemTypes.ResourceBlock: {
					rectangle.attr('body/fill', JointSettings.colors.berthplanner.block);
					rectangle.attr('body/stroke', JointSettings.colors.berthplanner.block_border);
					rectangle.attr('blockImage/fill', 'url(#backgroundSmallBlackPattern)');

					if (!operationsModel.dragabble) {
						rectangle.attr('body/cursor', 'default');
						rectangle.attr('label/cursor', 'default');
					}

					break;
				}
			}
			return rectangle;
		},
		setRoundedBorders: function (rectangle, rounded) {
			rectangle.attr('body/rx', rounded);
			rectangle.attr('body/ry', rounded);
		},
		setBlockImage(rectangle) {
			//rectangle.attr('blockImage/xlink:href', require('../img/stripes2.png'));
			//rectangle.attr('blockImage/preserveAspectRatio', 'xMidYMid slice');
			rectangle.attr('blockImage/width', rectangle.attributes.size.width);
			rectangle.attr('blockImage/height', rectangle.attributes.size.height);
			rectangle.attr('blockImage/x', 0);
			rectangle.attr('blockImage/y', 0);
		},
		setBerthingImage(rectangle, direction) {
			rectangle.attr('berthingImage/width', 50);

			if (direction === 'RTL') {
				rectangle.attr('berthingImage/xlink:href', require('../img/double-arrow-left-icon.png'));
				rectangle.attr('berthingImage/height', this.getBerthingImageHeight(rectangle));
				rectangle.attr('berthingImage/x', 10);
				rectangle.attr('berthingImage/y', rectangle.attributes.size.height / 2 - rectangle.attributes.attrs.berthingImage.height / 2);

				//rectangle.attr('body/clip-path', 'inset(0% 0% round 3rem 0rem 0rem 3rem)');
			}

			if (direction === 'LTR') {
				rectangle.attr('berthingImage/xlink:href', require('../img/double-arrow-right-icon.png'));
				rectangle.attr('berthingImage/height', this.getBerthingImageHeight(rectangle));
				rectangle.attr('berthingImage/x', rectangle.attributes.size.width - rectangle.attributes.attrs.berthingImage.width - 10);
				rectangle.attr('berthingImage/y', rectangle.attributes.size.height / 2 - rectangle.attributes.attrs.berthingImage.height / 2);

				//rectangle.attr('body/clip-path', 'inset(0% 0% round 0rem 3rem 3rem 0rem)');
			}

			/*
			if (!this.operationsModel.stop.LTR && !this.operationsModel.stop.RTL) {
				rectangle.attr('body/clip-path', 'inset(0% 0% round 1rem 1rem 1rem 1rem)');
				this.setRoundedBorders(JointSettings.shapes.berthplanner.rounded);
			}
			*/
		},
		getBerthingImageHeight(rectangle) {
			let margin = 20;

			if (rectangle.attributes.size.width < 220) {
				return 0;
			}

			if (rectangle.attributes.size.height < 30) {
				return 0;
			} else if (rectangle.attributes.size.height < 50 + margin) {
				return rectangle.attributes.size.height - margin > 1 ? rectangle.attributes.size.height - margin : 1;
			} else {
				return 50;
			}
		}
	}
);

export default JointCustomRectangle;
