<template>
	<v-layout align-center wrap class="resourceplanner-toolbar pa-0">
		<div>
			<v-btn text small @click.native="checkSaveChangesDialog(-1)">
				<v-icon>far fa-chevron-left</v-icon>
			</v-btn>
		</div>
		<v-flex xs10 md2 lg1>
			<v-menu
				v-model="menuDate"
				:close-on-content-click="false"
				:nudge-right="40"
				transition="scale-transition"
				offset-y
				max-width="290px"
				min-width="290px"
				style="z-index: 101"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-text-field
						v-model="selectedStringFormattedDate"
						v-on="on"
						v-bind="attrs"
						prepend-inner-icon="far fa-calendar"
						readonly
						solo
						flat
						hide-details
						class="date-field"
					></v-text-field>
				</template>
				<v-date-picker v-model="selectedStringDate" first-day-of-week="1" no-title @input="onDatePickerInput()"></v-date-picker>
			</v-menu>
		</v-flex>
		<div>
			<v-btn text small @click.native="checkSaveChangesDialog(1)">
				<v-icon>far fa-chevron-right</v-icon>
			</v-btn>
		</div>
		<resource-planner-save-changes-dialog ref="resourcePlannerSaveChangesDialog" @response="incrementOrDecrementDay" />
		<v-col xs12 md2 class="resourceplanner-toolbar__field mx-0 px-0" v-show="showPortSelect">
			<pui-select
				v-model="currentPort"
				modelName="port"
				itemValue="id"
				itemText="portname"
				:itemsToSelect="[currentPort]"
				return-object
				hide-details
				:placeholder="$t('berthplanner.port')"
				singleItemAutoselect
				@input="countPorts"
				ref="portSelect"
				toplabel
				clearable
				solo
				flat
			></pui-select>
		</v-col>
		<v-col xs12 md2 class="resourceplanner-toolbar__field mx-0 px-0">
			<pui-select
				v-model="currentResourceType"
				modelName="resourcetype"
				itemValue="id"
				itemText="name"
				:itemsToSelect="[currentResourceType]"
				:fixedFilter="portFixedFilter"
				return-object
				hide-details
				:placeholder="$t('form.resourcerequest.resourcetype')"
				toplabel
				clearable
				solo
				flat
			></pui-select>
		</v-col>
		<v-col xs12 md2>
			<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-btn color="primary" v-bind="attrs" v-on="on">{{ $t('berthplanner.additionalactions') }}</v-btn>
				</template>
				<v-list>
					<v-list-item>
						<v-btn color="primary" text>
							{{ 'Acción 1' }}
						</v-btn>
					</v-list-item>
					<v-list-item>
						<v-btn color="primary" text :disabled="false">
							{{ 'Acción 2' }}
						</v-btn>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-col>
		<v-spacer></v-spacer>
		<planner-toolbar-legend legendType="resourceplanner" class="pr-4" />
	</v-layout>
</template>

<script>
import ResourcePlannerSaveChangesDialog from './ResourcePlannerSaveChangesDialog.vue';
import PlannerToolbarLegend from '../../berthplanner/legend/PlannerToolbarLegend.vue';

export default {
	components: {
		ResourcePlannerSaveChangesDialog,
		PlannerToolbarLegend
	},
	props: {
		readOnly: {
			type: Boolean,
			required: true
		},
		savingEnabled: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			selectedDate: new Date(),
			selectedStringDate: null,
			menuDate: false,
			searchingText: '',
			currentPort: {},
			currentResourceType: {},
			stops: [],
			stopsKey: 1,
			dialog: false,
			dialogMeteo: false,
			showPortSelect: true
		};
	},
	computed: {
		portFixedFilter() {
			this.userBerthIds = this.$store.getters.getUserBerthIds;
			if (this.currentPort.portid) {
				return {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'portid', op: 'eq', data: this.currentPort.portid },
						{ field: 'id', op: 'in', data: this.userBerthIds }
					]
				};
			} else {
				return null;
			}
		},
		selectedStringFormattedDate() {
			if (!this.selectedStringDate) return null;

			const [year, month, day] = this.selectedStringDate.split('-');
			return `${day}/${month}/${year}`;
		}
	},
	watch: {
		currentPort() {
			this.$puiEvents.$emit('resourceplanner-toolbar_portSelected', this.currentPort);
		},
		currentResourceType() {
			this.$puiEvents.$emit('resourceplanner-toolbar_resourceTypeSelected', this.currentResourceType);
		},
		selectedStringDate() {
			this.$puiEvents.$emit('resourceplanner-toolbar_dateSelected', this.selectedDate);
		}
	},
	created() {
		this.selectedStringDate = this.fromDateToString(this.selectedDate);
	},
	methods: {
		countPorts() {
			this.$refs.portSelect.theItems.length < 2 ? (this.showPortSelect = false) : (this.showPortSelect = true);
		},
		validDate() {
			return (
				((this.selectedDate.getDay() == new Date().getDay() && this.selectedDate.getMonth() == new Date().getMonth()) ||
					this.selectedDate > new Date()) &&
				this.selectedDate.getUTCDate() < parseInt(new Date().getUTCDate() + 6)
			);
		},
		checkSaveChangesDialog(dayIncrement) {
			if (this.savingEnabled && !this.readOnly) {
				this.$refs.resourcePlannerSaveChangesDialog.dayIncrement = dayIncrement;
				this.$refs.resourcePlannerSaveChangesDialog.visibleDialog = true;
			} else {
				this.incrementOrDecrementDay(dayIncrement);
			}
		},
		incrementOrDecrementDay(dayIncrement) {
			this.selectedDate.setDate(this.selectedDate.getDate() + dayIncrement);
			this.selectedStringDate = this.fromDateToString(this.selectedDate);
		},

		fromDateToString(date) {
			const dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
			const MM = (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1);
			const yyyy = date.getFullYear();
			return `${yyyy}-${MM}-${dd}`;
		},
		onDatePickerInput() {
			this.menuDate = false;
			this.selectedDate = new Date(this.selectedStringDate);
		}
	}
};
</script>

<style lang="postcss">
.resourceplanner-toolbar {
	min-height: 65px;

	& .v-input__control {
		min-height: 40px !important;
	}
	&__field {
		margin-right: 24px;
	}
}
</style>
