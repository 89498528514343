import { dia, ui, shapes, elementTools, util, V, g } from '@clientio/rappid';
import JointSettings from '../../JointSettings.js';

import JointItem from '../../items/JointItem.js';
import JointItemConf from '../../items/JointItemConf.js';
import { JointItemTypes } from '../../items/JointTypes.js';

class JointHoursAxis {
	constructor(jointMain, topAxisHeight, leftAxisWidth, now) {
		this.jointMain = jointMain;

		this.hours = 24;
		this.now = now;

		this.topAxisHeight = topAxisHeight;
		this.leftAxisWidth = leftAxisWidth;

		this.topAxisItemWidth = (this.jointMain.paperWidth - this.leftAxisWidth) / this.hours;
	}

	render() {
		this.renderInitialInvisibleItem();
		this.renderItems();
		this.renderItemDelimiters();
		this.renderCurrentHourDelimiter();
	}

	// PRIMERA COLUMNA
	renderInitialInvisibleItem() {
		let jsItemConf = new JointItemConf(JointItemTypes.ResourceHour, '', 0, 0, this.leftAxisWidth, this.topAxisHeight);
		new JointItem(jsItemConf, this.jointMain).render();
	}

	// RENDER HORAS
	renderItems() {
		for (let n = 0; n < this.hours; n++) {
			let jsItemConf = new JointItemConf(
				JointItemTypes.ResourceHour,
				n,
				this.leftAxisWidth + this.topAxisItemWidth * n,
				0,
				this.topAxisItemWidth,
				this.topAxisHeight - 1
			);

			let hourItem = new JointItem(jsItemConf, this.jointMain);
			hourItem.render();
		}
	}

	// DELIMITADORES DE HORAS
	renderItemDelimiters() {
		for (let n = 0; n < this.hours; n++) {
			let line = V('line', {
				x1: this.leftAxisWidth + n * this.topAxisItemWidth,
				y1: this.topAxisHeight,
				x2: this.leftAxisWidth + n * this.topAxisItemWidth,
				y2: this.jointMain.paperHeight,
				stroke: JointSettings.colors.grid.lines,
				'stroke-width': 1
			});

			V(this.jointMain.paper.viewport).append(line);
		}
	}

	// DELIMITADOR HORA ACTUAL
	renderCurrentHourDelimiter() {
		const isNowVisible = this.now.getDate() == new Date().getDate();
		if (isNowVisible) {
			let line = V('line', {
				x1: this.leftAxisWidth + this.getXpositionFromDate(Date()),
				y1: 0,
				x2: this.leftAxisWidth + this.getXpositionFromDate(Date()),
				y2: this.jointMain.paperHeight,
				stroke: JointSettings.colors.grid.currentTime,
				'stroke-width': 2
			});

			V(this.jointMain.paper.viewport).append(line);
		}
	}

	getXpositionFromDate(date) {
		if (!date) {
			return null;
		}

		if (typeof date == 'string') {
			date = new Date(date);
		}

		if (date.getDate() < this.now.getDate() && !this.isDateTime(date)) {
			return 0;
		}

		if (date.getDate() > this.now.getDate() && !this.isDateTime(date)) {
			return 24 * this.topAxisItemWidth;
		}

		let hours, minutes;

		hours = parseInt(date.getHours()) * this.topAxisItemWidth;
		minutes = parseInt(date.getMinutes()) * (this.topAxisItemWidth / 60);

		return hours + minutes;
	}

	isDateTime(date) {
		return date.getYear() == 70;
	}

	getDateFromXPosition(date, x) {
		x = x - this.leftAxisWidth;
		let hours = Math.floor(x / this.topAxisItemWidth);
		x = x - this.topAxisItemWidth * hours;
		let minutes = Math.floor((60 * x) / this.topAxisItemWidth);

		date = new Date(date.setHours(hours));
		//date = new Date(date.setHours(hours - date.getTimezoneOffset() / 60));
		date = new Date(date.setMinutes(minutes));

		return date;
	}

	getPixelsPerHour() {
		return this.topAxisItemWidth;
	}
}

export default JointHoursAxis;
