<template>
	<div v-if="visible">
		<v-btn icon @click="dialog = true" style="margin-right: 5px" class="elevation-0 pui-toolbar__actionBtn primary white--text">
			<v-icon>far fa-info</v-icon>
		</v-btn>
		<v-dialog v-model="dialog" width="600">
			<v-card>
				<v-card-title>{{ $t('legend.legend') }}</v-card-title>
				<v-card-text class="px-10 pt-4">
					<v-row>
						<v-col cols="6">
							<h4 class="pb-4" style="color: black">
								{{ $t('form.resources.tabs.block').charAt(0) + $t('form.resources.tabs.block').slice(1).toLowerCase() }}
							</h4>
							<planner-legend-block
								class="float-left"
								:title="$t('form.berthblock.formtitle')"
								:tooltip="$t('form.berthblock.formtitle')"
								:image="'./block_legend.png'"
								:key="statusItems.length + 1"
							/>
							<planner-legend-block
								v-if="!isResourcePlanner"
								class="float-left"
								:title="$t('form.booking.formtitle')"
								:tooltip="$t('form.booking.formtitle')"
								:image="'./booking_legend.png'"
								:key="statusItems.length + 2"
							/>
						</v-col>
						<v-col cols="6">
							<h4 class="pb-4" style="color: black">{{ $t('legend.status') }}</h4>
							<planner-legend-block
								v-for="(statusItem, index) in statusItems"
								:title="getTitle(statusItem)"
								:tooltip="getTooltip(statusItem)"
								:color="getColor(statusItem)"
								:key="index"
							/>
						</v-col>
					</v-row>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="dialog = false">{{ $t('pui9.accept') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import PlannerLegendBlock from './PlannerLegendBlock.vue';

export default {
	name: 'PlannerToolbarLegend',
	components: { PlannerLegendBlock },
	props: {
		legendType: {
			type: String
		}
	},
	data() {
		return {
			visible: false, // DESHABILITADO HASTA QUE SE RECUPERE
			dialog: false,
			statusItems: null
		};
	},
	computed: {
		isResourcePlanner() {
			return this.legendType == 'resourceplanner';
		}
	},
	created() {
		this.getStatusItems();
	},
	methods: {
		getStatusItems() {
			if (this.legendType == 'resourceplanner') {
				this.statusItems = [];
			}

			if (this.legendType == 'berthplanner') {
				let stopsStatusValues =
					this.$store.getters.stopsStatusValues && this.$store.getters.stopsStatusValues.filter((status) => status.visibleberthingplan);
				stopsStatusValues.sort((a, b) => {
					return a.orderby - b.orderby;
				});
				this.statusItems = stopsStatusValues || [];
			}
		},
		getTitle(statusItem) {
			if (this.legendType == 'resourceplanner') {
				return statusItem.resourceallocationstatusname.charAt(0) + statusItem.resourceallocationstatusname.slice(1).toLowerCase();
			}

			if (this.legendType == 'berthplanner') {
				return statusItem.stopstatusname.charAt(0) + statusItem.stopstatusname.slice(1).toLowerCase();
			}
		},
		getTooltip(statusItem) {
			if (this.legendType == 'resourceplanner') {
				return statusItem.resourceallocationstatusname.charAt(0) + statusItem.resourceallocationstatusname.slice(1).toLowerCase();
			}

			if (this.legendType == 'berthplanner') {
				return this.$t(`stop.status.${statusItem.stopstatuscode.toLowerCase()}.tooltip`);
			}
		},
		getColor(statusItem) {
			if (this.legendType == 'resourceplanner') {
				return statusItem.statuscolor;
			}

			if (this.legendType == 'berthplanner') {
				return statusItem.colorhex;
			}
		}
	}
};
</script>
