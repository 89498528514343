<template>
	<div class="resourceplanner-container">
		<resource-planner-toolbar :readOnly="readOnly" :savingEnabled="savingEnabled" />
		<d-resource-planner-diagram
			v-if="resourceAllocationsLoaded && currentResourceType"
			v-model="plannerModel"
			ref="dResourcePlannerDiagram"
			:currentDate="currentDate"
			:currentResourceType="currentResourceType"
			:userFunctionalities="userFunctionalities"
			:readOnly="readOnly"
		/>
		<planner-legend
			legendType="resourceplanner"
			v-if="plannerModel.statusItems"
			:statusItems="plannerModel.statusItems"
			:savingEnabled="savingEnabled"
			:userFunctionalities="userFunctionalities"
		/>
	</div>
</template>

<script>
import ResourcePlannerToolbar from './toolbar/ResourcePlannerToolbar.vue';
import DResourcePlannerDiagram from './DResourcePlannerDiagram.vue';
import PlannerLegend from '../berthplanner/legend/PlannerLegend.vue';
import moment from 'moment';

export default {
	name: 'DResourcePlannerContainer',
	components: { ResourcePlannerToolbar, DResourcePlannerDiagram, PlannerLegend },
	data() {
		return {
			modelName: 'resourceplanner',
			currentDate: null,
			currentPort: null,
			currentResourceType: null,
			resourceAllocationsLoaded: null,
			plannerModel: {
				resourceAllocations: [],
				resourceBlocks: [],
				resourceTypes: [],
				resources: [],
				statusItems: []
			},
			savingEnabled: false,
			readOnly: false,
			allResourceAllocationStatus: []
		};
	},
	computed: {
		//
		// PERMISOS
		//
		has_READ_RESOURCEALLOCATION() {
			return this.$store.getters.hasFunctionality('READ_RESOURCEALLOCATION');
		},
		has_EDIT_RESOURCEALLOCATION() {
			return this.$store.getters.hasFunctionality('EDIT_RESOURCEALLOCATION');
		},
		userFunctionalities() {
			return {
				has_READ_RESOURCEALLOCATION: this.has_READ_RESOURCEALLOCATION,
				has_EDIT_RESOURCEALLOCATION: this.has_EDIT_RESOURCEALLOCATION,
				has_LIST_BERTHBLOCK: true,
				has_READ_BOOKING: false
			};
		}
	},
	watch: {
		resourceAllocationsLoaded(loaded) {
			this.$store.state.global.puiloader.show = !loaded;
		}
	},
	created() {
		this.getAllResourceAllocationStatus();
	},
	mounted() {
		this.resourceAllocationsLoaded = false;

		this.$puiEvents.$on('resourceplanner-toolbar_dateSelected', (dateSelected) => {
			this.savingEnabled = false;
			this.currentDate = dateSelected;
			this.getResourceAllocations();
		});
		this.$puiEvents.$on('resourceplanner-toolbar_portSelected', (portSelected) => {
			this.currentPort = portSelected;
			this.getResourceAllocations();
		});
		this.$puiEvents.$on('resourceplanner-toolbar_resourceTypeSelected', (resourceTypeSelected) => {
			this.currentResourceType = resourceTypeSelected;
			this.getResourceAllocations();
		});
		this.$puiEvents.$on('operationscountdown_reload', () => {
			this.getResourceAllocations();
		});
		this.$puiEvents.$on('resourceplanner-resourceBlock_removal', (id) => {
			this.savingEnabled = true;
			this.plannerModel.resourceBlocks.forEach((block) => {
				if (block.id == id) {
					block.delete = true;
				}
			});
		});
	},
	destroyed() {
		this.$puiEvents.$off('resourceplanner-toolbar_dateSelected');
		this.$puiEvents.$off('resourceplanner-toolbar_portSelected');
		this.$puiEvents.$off('resourceplanner-toolbar_resourceTypeSelected');

		this.$puiEvents.$off('resourceplanner-operationscountdown_reload');
		this.$puiEvents.$off('resourceplanner-resourceBlock_removal');
	},
	methods: {
		getAllResourceAllocationStatus() {
			const opts = {
				model: 'resourceallocationstatus'
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				this.allResourceAllocationStatus = response.data.data;
			});
		},
		async getResourceAllocations() {
			this.resourceAllocationsLoaded = false;

			this.plannerModel = {
				resourceAllocations: [],
				resourceBlocks: [],
				resourceTypes: [],
				resources: [],
				statusItems: this.allResourceAllocationStatus
			};

			let currentFormattedDate = this.currentDate && this.currentDate.toISOString().split('T')[0];

			const resourceFilter = {
				groupOp: 'and',
				groups: [
					{
						groupOp: 'or',
						rules: [
							{ field: 'disableddate', op: 'nu', data: null },
							{ field: 'disableddate', op: 'gt', data: currentFormattedDate }
						]
					}
				],
				rules: [
					{ field: 'resourcetypeid', op: 'eq', data: this.currentResourceType && this.currentResourceType.resourcetypeid },
					{ field: 'id', op: 'in', data: this.$store.getters.getPortsIds },
					{ field: 'port_authority_id', op: 'eq', data: this.$store.getters.getPortAuthorityId }
				]
			};

			await this.$puiRequests.postRequest(
				'/resources/listWithAttributes',
				{
					rows: -1,
					filter: resourceFilter
				},
				(response) => {
					if (response.data && response.data.data && response.data.data.length > 0) {
						this.plannerModel.resources = response.data.data;
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);

			await this.$puiRequests.postRequest(
				'/resourcetype/list',
				{
					rows: -1
				},
				(response) => {
					this.plannerModel.resourceTypes = [];

					if (response.data && response.data.data && response.data.data.length > 0) {
						this.plannerModel.resourceTypes = response.data.data;
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: 'error', vue: this });
				}
			);

			resourceFilter.groups = [
				{
					groupOp: 'or',
					groups: [],
					rules: [
						{ field: 'startdate', op: 'eq', data: currentFormattedDate },
						{ field: 'enddate', op: 'eq', data: currentFormattedDate },
						{ field: 'authstartdate', op: 'eq', data: currentFormattedDate },
						{ field: 'authenddate', op: 'eq', data: currentFormattedDate },
						{ field: 'actualstartdate', op: 'eq', data: currentFormattedDate },
						{ field: 'actualenddate', op: 'eq', data: currentFormattedDate }
					]
				}
			];

			await this.$puiRequests.postRequest(
				'/resourceallocation/list',
				{
					rows: -1,
					filter: resourceFilter
				},
				(response) => {
					if (response.data && response.data.data && response.data.data.length > 0) {
						this.plannerModel.resourceAllocations = response.data.data;

						this.plannerModel.resourceAllocations.forEach((resourceAllocation, index) => {
							switch (resourceAllocation.resourceallocationstatus) {
								case 'PL':
									resourceAllocation.plannerStartDate = resourceAllocation.startdate;
									resourceAllocation.plannerEndDate = resourceAllocation.enddate;
									break;
								case 'AU':
									resourceAllocation.plannerStartDate = resourceAllocation.authstartdate;
									resourceAllocation.plannerEndDate = resourceAllocation.authenddate;
									break;
								case 'PR':
									resourceAllocation.plannerStartDate = resourceAllocation.actualstartdate;
									resourceAllocation.plannerEndDate = resourceAllocation.authenddate;
									break;
								case 'FI':
									resourceAllocation.plannerStartDate = resourceAllocation.actualstartdate;
									resourceAllocation.plannerEndDate = resourceAllocation.actualenddate;
									break;
								case 'VA':
									resourceAllocation.plannerStartDate = resourceAllocation.actualstartdate;
									resourceAllocation.plannerEndDate = resourceAllocation.actualenddate;
									break;
							}

							let resourceType = this.plannerModel.resourceTypes.filter(
								(x) => x.resourcetypeid == resourceAllocation.resourcetypeid
							)[0];
							resourceAllocation.plannerMobilizationTime = resourceType.mobilizationtime;
							resourceAllocation.plannerDemobilizationTime = resourceType.demobilizationtime;

							resourceAllocation.extendedPlannerStartDate = moment(resourceAllocation.plannerStartDate)
								.subtract(resourceAllocation.plannerMobilizationTime, 'minutes')
								.toISOString();
							resourceAllocation.extendedPlannerEndDate = moment(resourceAllocation.plannerEndDate)
								.add(resourceAllocation.plannerDemobilizationTime, 'minutes')
								.toISOString();
						});
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);

			const blocksFilter = {
				groupOp: 'and',
				groups: [
					{
						groupOp: 'or',
						groups: [],
						rules: [
							{ field: 'startdate', op: 'eq', data: currentFormattedDate },
							{ field: 'enddate', op: 'eq', data: currentFormattedDate },
							{ field: 'starttime', op: 'nn', data: null },
							{ field: 'endtime', op: 'nn', data: null },
							{ field: 'days', op: 'cn', data: this.currentDate.getDay() + 1 }
						]
					}
				],
				rules: []
			};

			await this.$puiRequests.postRequest(
				'/resourceblock/list',
				{
					rows: -1,
					filter: blocksFilter
				},
				(response) => {
					this.plannerModel.resourceBlocks = [];

					if (response.data && response.data.data && response.data.data.length > 0) {
						this.plannerModel.resourceBlocks = response.data.data;

						this.plannerModel.resourceBlocks.forEach((resourceBlock) => {
							if (resourceBlock.days != null) {
								resourceBlock.plannerStartDate = new Date(this.currentDate.toISOString().split('T')[0] + 'T00:00:00');
							}
							if (resourceBlock.days != null) {
								resourceBlock.plannerEndDate = new Date(this.currentDate.toISOString().split('T')[0] + 'T23:59:59');
							}

							if (resourceBlock.starttime != null) {
								resourceBlock.plannerStartDate = resourceBlock.starttime;
							}
							if (resourceBlock.endtime != null) {
								resourceBlock.plannerEndDate = resourceBlock.endtime;
							}

							if (resourceBlock.startdate != null) {
								resourceBlock.plannerStartDate = resourceBlock.startdate;
							}
							if (resourceBlock.enddate != null) {
								resourceBlock.plannerEndDate = resourceBlock.enddate;
							}
						});
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);

			this.resourceAllocationsLoaded = true;
		},

		// -------------------

		back() {
			this.savingEnabled = false;
			this.getResourceAllocations();
		},
		async save() {
			await this.plannerModel.resourceAllocations.forEach((resourceAllocation) => {
				switch (resourceAllocation.resourceallocationstatus) {
					case 'PL':
						resourceAllocation.startdate = resourceAllocation.plannerStartDate;
						resourceAllocation.enddate = resourceAllocation.plannerEndDate;
						break;
					case 'AU':
						resourceAllocation.authstartdate = resourceAllocation.plannerStartDate;
						resourceAllocation.authenddate = resourceAllocation.plannerEndDate;
						break;
					case 'PR':
						//resourceAllocation.actualstartdate = resourceAllocation.plannerStartDate;
						resourceAllocation.authenddate = resourceAllocation.plannerEndDate;
						break;
					/*
					case 'FI':
						resourceAllocation.actualstartdate = resourceAllocation.plannerStartDate;
						resourceAllocation.actualenddate = resourceAllocation.plannerEndDate;
						break;
					case 'VA':
						resourceAllocation.actualstartdate = resourceAllocation.plannerStartDate;
						resourceAllocation.actualenddate = resourceAllocation.plannerEndDate;
						break;
					*/
				}

				new Promise(async (resolve) => {
					await this.$puiRequests.patchRequest(
						'/resourceallocation/patch?resourceallocationid=' + resourceAllocation.resourceallocationid,
						resourceAllocation,
						() => {
							this.$puiNotify.success(this.$t('pui9.save.success'));
							resolve(true);
						},
						(error) => {
							this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
							resolve(false);
						}
					);
				});
			});

			await this.plannerModel.resourceBlocks.forEach((resourceBlock) => {
				resourceBlock.days = null;
				resourceBlock.starttime = null;
				resourceBlock.endtime = null;
				resourceBlock.startdate = resourceBlock.plannerStartDate;
				resourceBlock.enddate = resourceBlock.plannerEndDate;

				new Promise(async (resolve) => {
					if (resourceBlock.delete != true) {
						await this.$puiRequests.patchRequest(
							'/resourceblock/patch?id=' + resourceBlock.id,
							resourceBlock,
							() => {
								this.$puiNotify.success(this.$t('pui9.save.success'));
							},
							(error) => {
								this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
								resolve(false);
							}
						);
					} else {
						await this.$puiRequests.deleteRequest(
							'/resourceBlock/delete?id=' + resourceBlock.id,
							null,
							() => {
								this.$puiNotify.success(this.$t('pui9.save.success'));
							},
							(error) => {
								this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
								resolve(false);
							}
						);
					}

					resolve(true);
				});
			});

			this.savingEnabled = false;

			// patch stop -> stop allocation -> v_stop_berthplanner ~ 150/200 ms
			setTimeout(() => {
				this.getResourceAllocations();
			}, 500);
		}
	}
};
</script>
