import { dia, ui, shapes, elementTools, util, V, g } from '@clientio/rappid';
import JointSettings from './JointSettings.js';
import JointZManager from './utils/JointZManager.js';

class JointMain {
	constructor(containerid, $store, $puiEvents, $puiI18n) {
		this.containerid = containerid;
		this.jointZManager = new JointZManager();
		this.$puiEvents = $puiEvents;
		this.$puiI18n = $puiI18n;
		this.storeState = $store._modules.root.state;

		let menuWidth = 64;
		if (this.storeState.menu.expandMenu || this.storeState.menu.secondMenuShowed) {
			menuWidth = 304;
		}

		this.paperWidth = window.innerWidth - menuWidth;
		this.paperHeight = window.innerHeight - 138;

		this.initializePaper();
		this.drawPaper();
	}

	drawPaper() {
		//this.el.querySelector('.paper-container').appendChild(paperScroller.el);
		document.getElementById(this.containerid).firstChild && document.getElementById(this.containerid).firstChild.remove();
		document.getElementById(this.containerid).append(this.paper.el);
	}

	initializePaper() {
		const graph = (this.graph = new dia.Graph({}, { cellNamespace: shapes }));
		this.commandManager = new dia.CommandManager({ graph });

		var constraintElementView = dia.ElementView.extend({
			events: {
				mouseover: 'handleMouseOver'
			},

			handleMouseOver: function (evt, x, y) {
				// var size = this.model.get('size');
				// console.log(evt);
			}
		});

		this.paper = new dia.Paper({
			cellViewNamespace: shapes,
			elementView: constraintElementView,
			width: this.paperWidth,
			height: this.paperHeight,
			gridSize: 1,
			/*drawGrid: {
				name: 'dot',
				color: JointSettings.colors.grid.dots
			},*/
			model: this.graph,
			background: { color: JointSettings.colors.paper },
			//defaultLink: new shapes.app.Link(),
			//defaultConnectionPoint: shapes.app.Link.connectionPoint,
			//interactive: { linkMove: false },
			//sorting: dia.Paper.sorting.APPROX,
			async: true
		});

		// DEFS

		if (!this.blackPattern) {
			// this.pattern = V('<pattern id="pepe" patternUnits="userSpaceOnUse"><image/></pattern>');
			// cache the image element for a quicker access
			// this.patternImage = this.pattern.findOne('image');

			this.blackPattern = V(
				'<pattern id="backgroundBlackPattern" x="0" y="0" width="400" height="227" patternUnits="userSpaceOnUse">' +
					'<line x1="-300" y1="-10" x2="100" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
					'<line x1="-250" y1="-10" x2="150" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
					'<line x1="-200" y1="-10" x2="200" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
					'<line x1="-150" y1="-10" x2="250" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
					'<line x1="-100" y1="-10" x2="300" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
					'<line x1="-50" y1="-10" x2="350" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
					'<line x1="0" y1="-10" x2="400" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
					'<line x1="50" y1="-10" x2="450" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
					'<line x1="100" y1="-10" x2="500" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
					'<line x1="150" y1="-10" x2="550" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
					'<line x1="200" y1="-10" x2="600" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
					'<line x1="250" y1="-10" x2="650" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
					'<line x1="300" y1="-10" x2="700" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
					'<line x1="350" y1="-10" x2="750" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
					'<line x1="400" y1="-10" x2="800" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
					'<line x1="450" y1="-10" x2="850" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
					'<line x1="500" y1="-10" x2="900" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
					'</pattern>'
			);

			V(this.paper.svg).defs().append(this.blackPattern);
		}

		if (!this.microWhitePattern) {
			this.microWhitePattern = V(
				'<pattern id="backgroundMicroWhitePattern" x="0" y="0" width="120" height="800" patternUnits="userSpaceOnUse">' +
					'<rect fill="white" width="210" height="800" opacity="0.5"></rect>' +
					'<line x1="-140" y1="-5" x2="300" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
					'<line x1="-120" y1="-5" x2="320" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
					'<line x1="-100" y1="-5" x2="340" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
					'<line x1="-80" y1="-5" x2="360" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
					'<line x1="-60" y1="-5" x2="380" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
					'<line x1="-40" y1="-5" x2="400" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
					'<line x1="-20" y1="-5" x2="420" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
					'<line x1="0" y1="-5" x2="440" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
					'<line x1="20" y1="-5" x2="460" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
					'<line x1="40" y1="-5" x2="480" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
					'<line x1="60" y1="-5" x2="500" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
					'<line x1="80" y1="-5" x2="520" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
					'<line x1="100" y1="-5" x2="540" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
					'<line x1="120" y1="-5" x2="560" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
					'<line x1="140" y1="-5" x2="580" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
					'</pattern>'
			);

			V(this.paper.svg).defs().append(this.microWhitePattern);
		}

		if (!this.smallBlackPattern) {
			this.smallBlackPattern = V(
				'<pattern id="backgroundSmallBlackPattern" x="0" y="0" width="210" height="800" patternUnits="userSpaceOnUse">' +
					'<line x1="-210" y1="-10" x2="300" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
					'<line x1="-180" y1="-10" x2="330" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
					'<line x1="-150" y1="-10" x2="360" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
					'<line x1="-120" y1="-10" x2="390" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
					'<line x1="-90" y1="-10" x2="420" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
					'<line x1="-60" y1="-10" x2="450" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
					'<line x1="-30" y1="-10" x2="480" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
					'<line x1="0" y1="-10" x2="510" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
					'<line x1="30" y1="-10" x2="540" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
					'<line x1="60" y1="-10" x2="570" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
					'<line x1="90" y1="-10" x2="600" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
					'<line x1="120" y1="-10" x2="630" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
					'<line x1="150" y1="-10" x2="660" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
					'<line x1="180" y1="-10" x2="690" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
					'<line x1="210" y1="-10" x2="720" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
					'</pattern>'
			);

			V(this.paper.svg).defs().append(this.smallBlackPattern);
		}

		if (!this.yellowPattern) {
			this.yellowPattern = V(
				'<pattern id="backgroundYellowPattern" x="0" y="0" width="400" height="800" patternUnits="userSpaceOnUse">' +
					'<line x1="-150" y1="-10" x2="100" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
					'<line x1="-100" y1="-10" x2="150" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
					'<line x1="-50" y1="-10" x2="200" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
					'<line x1="0" y1="-10" x2="250" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
					'<line x1="50" y1="-10" x2="300" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
					'<line x1="100" y1="-10" x2="350" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
					'<line x1="150" y1="-10" x2="400" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
					'<line x1="200" y1="-10" x2="450" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
					'<line x1="250" y1="-10" x2="500" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
					'<line x1="300" y1="-10" x2="550" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
					'<line x1="350" y1="-10" x2="600" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
					'<line x1="400" y1="-10" x2="650" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
					'<line x1="450" y1="-10" x2="700" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
					'<line x1="500" y1="-10" x2="750" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
					'</pattern>'
			);

			V(this.paper.svg).defs().append(this.yellowPattern);
		}

		/*

		this.snaplines = new ui.Snaplines({ paper: paper });

		const paperScroller = (this.paperScroller = new ui.PaperScroller({
			baseWidth: 1000,
			baseHeight: 1000,
			paper,
			autoResizePaper: true,
			scrollWhileDragging: true,
			cursor: 'grab'
		}));

		paperScroller.render().adjustPaper().center();

		paper.on('paper:pan', (evt, tx, ty) => {
			evt.preventDefault();
			paperScroller.el.scrollLeft += tx;
			paperScroller.el.scrollTop += ty;
		});

		paper.on('paper:pinch', (evt, ox, oy, scale) => {
			// the default is already prevented
			const zoom = paperScroller.zoom();
			paperScroller.zoom(zoom * scale, { min: 0.2, max: 5, ox, oy, absolute: true });
		});
		
		*/
	}
}

export default JointMain;
