class JointItemConf {
	constructor(itemType, label, x, y, width, height) {
		this.itemType = itemType;
		this.label = label;
		this.position = {
			x: x,
			y: y
		};
		this.size = {
			width: width,
			height: height
		};
	}
}

export default JointItemConf;
